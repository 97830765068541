<template>
  <div class="receiptParticle">
    <template v-for="(item,index) in items" >
      <div v-if="item.isShow" :key="index" class="dis_flex">
        <span class="font_12 color_gray text-left line_height_24 " style="flex:2;">
        {{item.title}} <span v-if="IsLargess" class="font_12" size="mini">【赠】</span></span>
        <span v-if="!item.isHiddenPrice">
          <span v-if="item.isNumFormat"  class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.des | NumFormat}}</span> 
          <span v-else class="font_12 text_right line_height_24" style="flex:1;">{{item.des}}</span>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
 name: 'receiptParticle',
  props:{
    items:{
      type:Array,
      require:true,
    },
    IsLargess:{
      type:Boolean,
      default:false,
    }
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {},
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  实例被挂载后调用  */
  mounted() {},
  beforeUpdate(){
    console.log('beforeUpdate');

  },
  updated(){
    console.log('updated');
  }

}
</script>

<style lang="scss">

.receiptParticle{

 }
</style>
